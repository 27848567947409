.app-head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-weight: bold;
  color: #ddd;
  background-color: rgba(18, 18, 18, 0.9);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 1px;
  z-index: 100;

  .head-title {
    font-size: 18px;
    font-weight: bold;
    color: #f0f0f0;
    margin-right: 10px;
  }
  nav {
    display: inline-block;
    position: relative;
    top: 1px;
    padding: 0 10px;
    &:first-of-type {
      margin-left: 80px;
    }
    a {
      color: inherit;
      font-size: 16px;
      text-decoration: none;
      &:hover {
        color: #f0f0f0;
      }
    }
  }
  .headerBtn {
    position: absolute;
    right: 20px;
    height: 30px;
    width: 40px;
    padding: 1px 0;
    border: 1px solid #111;
    border-radius: 6px;
    background-color: transparent;
  }
  .btnImg {
    height: 25px;
    width: 25px;
    background-color: transparent;
  }
}