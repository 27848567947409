.feedContainer {
    height: 100%;
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    background-color: #141414;
    .feedItem {
        width: 100%;
        min-height: 100%;
        margin: 2px auto;
        overflow: hidden;
        position: relative;
        border-bottom: 3px solid #222;
        img {
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            border-radius: 8px;
        }
        .imgLabel {
            color: #eee;
            position: absolute;
            top: 50px;
            left: 15px;
            right: 15px;
            font-size: 28px;
            font-weight: bold;
            -webkit-text-stroke: 1px #222;
            text-shadow: -1px -1px 0 rgba(0,0,0,0.2),
                1px -1px 0 rgba(0,0,0,0.2),
                -1px 1px 0 rgba(0,0,0,0.2),
                1px 1px 0 rgba(0,0,0,0.2);
            z-index: 5;
        }
    }
}