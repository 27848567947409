body {
  text-align: center;
  background-color: rgb(18, 18, 18);
}

.app {
  height: 100vh;
  position: relative;
}

.app-logo {
  width: 46px;
  height: 46px;
  border-radius: 6px;
  margin: 0 4px;
  pointer-events: none;
}

.app-content {
  position: absolute;
  top: 50px;
  width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
}