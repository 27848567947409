.app-foot {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-weight: bold;
  color: #ddd;
  background-color: rgba(18, 18, 18, 0.9);
  box-shadow: rgba(255, 255, 255, 0.12) 0px 1px 1px;
  z-index: 100;

  .feedBtn {
    position: absolute;
    bottom: 6px;
    height: 30px;
    width: 40px;
    padding: 1px 0;
    background-color: transparent;
    border: 1px solid #111;
    border-radius: 6px;
    &.prev {
        left: calc(50% - 55px);
    }
    &.next {
        left: calc(50% + 15px);
    }
    &.dislike {
        left: calc(50% - 120px);
    }
    &.like {
        left: calc(50% + 80px);
    }
    &.share {
        right: 10px;
    }
  }
  .btnImg {
    height: 25px;
    width: 25px;
    background-color: transparent;
  }
}