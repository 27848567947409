.maplibregl-map {
  max-width: calc(100vw - 20px);
  margin: 16px auto;
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  border-bottom-color: #aaa;
  border-right-color: #aaa;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.maplibregl-marker {
    width: 42px;
    >img {
        width: 100%;
    }
}
.maplibregl-popup-content {
    padding: 4px 8px;
}
.maplibregl-popup-close-button{
    display: none;
}